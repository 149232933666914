// Generated by Framer (508aa67)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {HwzjhKAYi: {hover: true}};

const cycleOrder = ["HwzjhKAYi"];

const serializationHash = "framer-00KqG"

const variantClassNames = {HwzjhKAYi: "framer-v-hxl1n4"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 0.3, ease: [0.12, 0.23, 0.5, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, hellostelarstudioGmailCom, id, title, width, ...props}) => { return {...props, FRsSg50xQ: title ?? props.FRsSg50xQ ?? "Get Started", gtXTdXHYc: hellostelarstudioGmailCom ?? props.gtXTdXHYc} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;hellostelarstudioGmailCom?: string;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, gtXTdXHYc, FRsSg50xQ, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "HwzjhKAYi", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={gtXTdXHYc} openInNewTab={false} smoothScroll><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-hxl1n4", className, classNames)} framer-93u8ub`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"HwzjhKAYi"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 30, borderBottomRightRadius: 30, borderTopLeftRadius: 30, borderTopRightRadius: 30, ...style}} {...addPropertyOverrides({"HwzjhKAYi-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItNTAw", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "14px", "--framer-font-weight": "500", "--framer-text-alignment": "center"}}>Get Started</motion.p></React.Fragment>} className={"framer-9jgn6e"} fonts={["GF;Inter-500"]} layoutDependency={layoutDependency} layoutId={"BKKZCHGS7"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={FRsSg50xQ} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({"HwzjhKAYi-hover": {children: <React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItNTAw", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "18px", "--framer-font-weight": "500", "--framer-text-alignment": "center"}}>Get Started</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-00KqG.framer-93u8ub, .framer-00KqG .framer-93u8ub { display: block; }", ".framer-00KqG.framer-hxl1n4 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 40px; justify-content: center; overflow: visible; padding: 15px; position: relative; text-decoration: none; width: auto; }", ".framer-00KqG .framer-9jgn6e { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-00KqG.framer-hxl1n4 { gap: 0px; } .framer-00KqG.framer-hxl1n4 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-00KqG.framer-hxl1n4 > :first-child { margin-left: 0px; } .framer-00KqG.framer-hxl1n4 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 107.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]},"hki2AdUBz":{"layout":["auto","fixed"]}}}
 * @framerVariables {"gtXTdXHYc":"hellostelarstudioGmailCom","FRsSg50xQ":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerWe4ENSIOr: React.ComponentType<Props> = withCSS(Component, css, "framer-00KqG") as typeof Component;
export default FramerWe4ENSIOr;

FramerWe4ENSIOr.displayName = "Button-Nav";

FramerWe4ENSIOr.defaultProps = {height: 40, width: 107.5};

addPropertyControls(FramerWe4ENSIOr, {gtXTdXHYc: {title: "hellostelarstudio@gmail.com", type: ControlType.Link}, FRsSg50xQ: {defaultValue: "Get Started", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerWe4ENSIOr, [{explicitInter: true, fonts: [{family: "Inter", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZ1rib2Bg-4.woff2", weight: "500"}]}], {supportsExplicitInterCodegen: true})